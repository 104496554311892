.conversation-player-container {
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
  user-select: none;
  background-image:var(--shared-assets-fond-jpg,url('../../assets/fond.jpg'));
  background-color:#000;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .conversation-mask {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:radial-gradient(#000c, #000f);
  }
  &.administration {
    .conversation-mask {
      background:radial-gradient(#00021cee, #00021cff);
    }
  }
  .conversation-player-scroller {
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    color:#fff;
    overflow-y: auto;
    scroll-behavior: smooth;
    .conversation-player {
      overflow:hidden;
      position:absolute;
      top:0;
      left:var(--left);
      transform-origin: left top;
      transform:scale(var(--ratio));
      width:var(--width);
      .portrait-photo {
        margin-top:30px;
        height:300px;
        width:300px;
        border-radius:150px;
        margin-left:calc( ( var(--width) - 300px ) / 2 );
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        box-shadow: 0 0 20px #000;
      }
      .portrait-nom {
        margin:15px 0;
        font-size:90px;
        text-align:center;
        font-family:'francis-regular';
      }
      .portrait-chapeau {
        font-size:25px;
        text-align:center;
        padding: 0 30px;
      }
      .portrait-separateur {
        margin:30px auto;
        padding:15px 0;
        background-color:#444a;
        border-radius:8px;
        width: calc( var(--width) - 156px );
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .portrait-interlocuteur {
        margin:5px auto;
        width: calc( var(--width) - 156px );
        display: flex;
        justify-content: flex-start;
        overflow: hidden;
        box-sizing: border-box;
        padding-left:10%;
        .portrait-interlocuteur-avatar {
          width:50px;
          height:50px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size:25px;
          position:relative;
          background-image: var(--shared-assets-avatar-full-svg,url('../../assets/avatar-full.svg'));
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          margin-right:10px;
          margin-bottom:10px;
        }
        .portrait-interlocuteur-text {
          max-width:70%;
          .portrait-interlocuteur-nom {
            font-family:'francis-medium';
            font-size:55px;
            line-height:50px;
            position:relative;
            top:-5px;
          }
          .portrait-interlocuteur-presentation {
            position:relative;
            white-space: pre-line;
          }
        }
      }
    }
    .bottom-item {
      height:100px;
    }
  }
}
