.main-menu {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:#000e;
  opacity:0;
  transition: opacity 0.5s;
  pointer-events: none;
  z-index:1000;
  &.show {
    pointer-events: auto;
    opacity:1;
  }
  .main-menu-inner {
    position:absolute;
    width:var(--width);
    height:var(--height);
    left:var(--left);
    top:var(--top);
    transform-origin: top left;
    transform: scale(var(--ratio));
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .main-menu-item {
      border-top:1px solid #FFF;
      &:last-of-type { border-bottom:1px solid #FFF; }
      color:#FFF;
      font-family:'francis-regular';
      font-size:55px;
      line-height:1;
      padding-bottom:0.3em;
      white-space: pre-line;
      max-width:65%;
      width:500px;
      text-align: center;
      transition: background-color 0.5s;
      cursor:pointer;
      &:hover {
        background-color: #FFF2;
      }
    }
  }
}
