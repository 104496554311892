.menu-container-top, .menu-container-bottom {
  position:absolute;
  width:100%;
  opacity:1;
  transition:opacity 0.5s;
  .menu-inner {
    position:absolute;
    height:65px;
    width:var(--width);
    left:var(--left);
    &.full-width {
      width:var(--reverseWindowWidth);
      left:0;
    }
    transform:scale(var(--ratio));
    display:flex;
    justify-content: space-evenly;
    align-items: center;
    padding:0 15px;
    box-sizing:border-box;
    .menu-item {
      position:relative;
      width:50px;
      height:50px;
      opacity:0.8;
      transition:opacity 0.2s;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display:flex;
      justify-content: center;
      align-items: center;
      color:#FFF;
      cursor:pointer;
      &:hover {
        opacity:1;
      }
      &.menu-home {
        background-image: var(--shared-assets-home-svg,url('../../assets/home.svg'));
      }
      &.menu-settings {
        background-image: var(--shared-assets-cog-svg,url('../../assets/cog.svg'));
      }
      &.menu-tapuscrit {
        background-image: var(--shared-assets-tapuscrit-svg,url('../../assets/tapuscrit.svg'));
      }
      &.menu-portrait {
        background-image: var(--shared-assets-bulles-svg,url('../../assets/bulles.svg'));
      }
      &.menu-lettres {
        background-image: var(--shared-assets-lettre-svg,url('../../assets/lettre.svg'));
      }
      &.menu-plus {
        background-image: var(--shared-assets-more-svg,url('../../assets/more.svg'));
      }
      &.menu-voix {
        background-image: var(--shared-assets-voix-svg,url('../../assets/voix.svg'));
      }
      &.menu-pause {
        background-image: var(--shared-assets-pause-svg,url('../../assets/pause.svg'));
      }
      &.menu-play {
        background-image: var(--shared-assets-play-svg,url('../../assets/play.svg'));
      }
      &.menu-stop {
        background-image: var(--shared-assets-stop-svg,url('../../assets/stop.svg'));
      }
      &.menu-menu {
        background-image: var(--shared-assets-burger-svg,url('../../assets/burger.svg'));
      }
      &.menu-close {
        background-image: var(--shared-assets-close-svg,url('../../assets/close.svg'));
      }
    }
    .combo {
      position:relative;
      .menu-item-secondary {
        position:absolute;
        width:30px;
        height:30px;
        bottom:70px;
        left:10px;
      }
    }
  }
}
.menu-container-top {
  top:0px;
  width:100%;
  left:0;
  .menu-inner {
    padding-top:15px;
    transform-origin: top left;
  }
}
.menu-container-bottom {
  top:calc( 100% - 65px ) ;
  .menu-inner {
    padding-bottom:15px;
    transform-origin: bottom left;
  }
}
