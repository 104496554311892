.lettre {
  width:100%;
  height:100%;
  background-color: #000;
  overflow:hidden;
  cursor:grab;
  &.lettre-inner, &.lettre-innerdiv>div {
    width:100%;
    height:100%;
  }
  .lettre-mask {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    pointer-events: none;
    background: radial-gradient(#0000, #000C);
  }
  .menu-container-top, .menu-container-bottom {
    pointer-events: auto;
  }
  &.menu-hide {
    .menu-container-top, .menu-container-bottom {
      opacity:0;
      pointer-events: none;
    }
  }
}
