.markdown-preview .MuiTypography-h1 {
  font-size:40px;
  margin-top:15px;
  &:first-child {
    margin-top:0;
  }
}
.markdown-preview .MuiTypography-h2 {
  margin-top:15px;
  &:first-child {
    margin-top:0;
  }
  font-size:30px;
}
.markdown-preview .MuiTypography-h3 {
  margin-top:15px;
  &:first-child {
    margin-top:0;
  }
  font-size:24px;
}
.markdown-preview .MuiTableContainer-root {
  margin-bottom:15px;
}
