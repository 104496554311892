.lien {
  height:100%;
  border-radius:16px;
  overflow:hidden;
  position:relative;
  .lien-content {
    position: relative;
    background-color:#FFF;
    h3 {
      font-size:30px;
      line-height: 1;
      margin:15px 0;
    }
    height:100%;
    overflow-y: auto;
    .lien-content-inner {
      padding:0 15px 30px 15px;
      white-space: pre-line;
    }
  }
  .lien-images {
    position:absolute;
    width:100%;
    height:40%;
    max-height: 300px;
    background-color: #000;
    left:0;
    bottom:0;
    box-shadow: 0 -15px 15px #FFF;
    .swiper {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      .swiper-pagination-bullet {
        background-color: #FFF;
        box-shadow: 0 0 20px #000;
        opacity: 0.5;
        &.swiper-pagination-bullet-active {
          opacity: 1;
        }
      }
    }
    img {
      width:100%;
      height:100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &.has-images {
    .lien-content {
      height:60%;
      min-height: calc(100% - 300px);
    }
  }
}
