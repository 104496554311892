.conversation-player-conversation {
  margin-top:50px;
  .conversation-interlocuteurs {
    display: flex;
    justify-content: center;
    border-top: 2px solid #FFF;
    .conversation-interlocuteur {
      width:60px;
      height:50px;
      position:relative;
      .conversation-interlocuteur-avatar {
        width:100%;
        height:100%;
        position:relative;
        overflow:hidden;
        &>div {
          width:50px;
          height:50px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size:25px;
          margin:0 5px;
          position:relative;
          top:-8px;
          background-image: var(--shared-assets-avatar-full-svg,url('../../assets/avatar-full.svg'));
          background-position: center -3px;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      .conversation-interlocuteur-tooltip {
        position:absolute;
        z-index:10;
        top:100%;
        width:500px;
        left:calc(50% - 250px);
        opacity:0;
        transition:opacity 0.5s;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
        &>div {
          max-width:300px;
          padding:10px;
          border-radius:8px;
          border: 2px solid #FFF;
          background-color:#000e;
          .conversation-interlocuteur-tooltip-nom {
            font-family:'francis-medium';
            font-size:40px;
            line-height:0.8;
            position:relative;
            top:-5px;
            width:100%;
          }
          .conversation-interlocuteur-tooltip-presentation {
            margin-top:10px;
            position:relative;
            white-space: pre-line;
          }
        }
      }
      &.show-tooltip .conversation-interlocuteur-tooltip{
        opacity:1;
      }
    }
  }
  .conversation-from {
    font-size:20px;
    font-weight:bold;
    margin-top:30px;
  }
  .conversation-message {
    padding:0 80px;
  }
  .conversation-message-content {
    display: flex;
    .conversation-message-bulle {
      padding:10px;
      font-size:22px;
      margin: 5px 0;
      position:relative;
      min-width: 10px;
      min-height:10px;
      .lien {
        cursor:pointer;
        text-decoration: underline;
        text-decoration-thickness: var(--pixelSize);
        font-weight: bold;
      }
      .text {
        position:relative;
        z-index:1;
        white-space: pre-line;
      }
    }
    .conversation-message-failed {
      font-size:0.8em;
      color:#FFF;
      .failed-icon {
        display: inline-block;
        position:relative;
        bottom:-4px;
        height:16px;
        width:16px;
        background-size: contain;
        background-image:var(--shared-assets-failed-svg,url('../../assets/failed.svg'));
      }
    }
    .conversation-message-missed {
      background-color:#3e3e3e;
      border-radius: 4px;
      color:#FFF;
      margin: 5px 0;
      padding:5px;
      line-height:20px;
      .missed-call-icon {
        display: inline-block;
        position:relative;
        bottom:-2px;
        height:16px;
        width:16px;
        background-size: contain;
        background-image:var(--shared-assets-missed-call-svg,url('../../assets/missed-call.svg'));
      }
    }
  }
  .conversation-message-right {
    text-align: right;
    .conversation-message-content {
      justify-content: flex-end;
    }
  }
  .conversation-message-left {
    text-align: left;
    .conversation-message-content {
      justify-content: flex-start;
      color:#000;
    }
  }
  .conversation-header {
    .conversation-titre {
      font-size:25px;
      height:1.7em;
      position:relative;
      .conversation-date {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        text-align: center;
        margin:5px 0;
        opacity:1;
        transition: opacity 0.5s;
      }
      .conversation-interlocuteur {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        text-align: center;
        margin:5px 0;
        opacity:0;
        transition: opacity 0.5s;
      }
      &.show {
        .conversation-date {
          opacity:0;
        }
        .conversation-interlocuteur {
          opacity:1;
        }
      }
    }
  }
}
