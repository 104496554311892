.loader {
  font-size: 200px;
  color:#FFF;
  background-color: #000;
  position:fixed;
  width:100%;
  height:100%;
  z-index:1000;
  .loader-animation {
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
  }
  .loader-progress {
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    .loader-bar-container {
      opacity:1;
      transition:opacity 0.5s;
      width:500px;
      max-width:90%;
      background-color:#FFF2;
      .loader-bar {
        background-color:#FFF;
        height:5px;
      }
    }
    .need-action {
      font-size:16px;
    }
  }
  &.loaded {
    .loader-progress {
      .loader-bar-container {
        opacity:0;
      }
    }
  }
}
